import { Injectable } from '@angular/core';

export enum FeatureTag {
  VOIR_BONS_SITES_PERMIS = 'Voir tous les bons des sites permis'
}

@Injectable({
  providedIn: 'root'
})
export class FeatureTagService {
  private featureTags: FeatureTag[] = [];

  constructor() {
    this.loadFeatureTags();
  }

  private loadFeatureTags(): void {
    const storedTags = localStorage.getItem('featureTags');
    this.featureTags = storedTags ? JSON.parse(storedTags) : [];
  }

  getFeatureTags(): FeatureTag[] {
    return this.featureTags;
  }

  updateFeatureTag(feature: FeatureTag, isEnabled: boolean): void {
    if (isEnabled) {
      if (!this.featureTags.includes(feature)) {
        this.featureTags.push(feature);
      }
    } else {
      this.featureTags = this.featureTags.filter(f => f !== feature);
    }
    this.saveFeatureTags();
  }

  isFeatureEnabled(feature: FeatureTag): boolean {
    return this.featureTags.includes(feature);
  }

  private saveFeatureTags(): void {
    localStorage.setItem('featureTags', JSON.stringify(this.featureTags));
  }

  static canActivate(feature: FeatureTag): boolean {
    const storedTags = localStorage.getItem('featureTags');
    const featureTags = storedTags ? JSON.parse(storedTags) : [];
    return featureTags.includes(feature);
  }
}
