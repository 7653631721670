import { AfterContentInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  BonsLexiClient,
  DocumentType,
  EvenementTypeDto,
  EvenementTypesLexiClient,
  FluxEtapeDto,
  FluxLexiClient,
  FluxStatut,
  ImportBonResult,
  LexiMouvementTypeDto,
  LexiMouvementTypesLexiClient,
  MouvementSens,
  ObjectType,
  Permissions,
  TypeImportBon
} from "@lexi-clients/lexi";
import { DxDataGridComponent } from "devextreme-angular";
import CustomStore from "devextreme/data/custom_store";
import { BonListService } from "../../../../../services/bon.service";
import { DxDataSourceService } from "../../../../../shared/services/dx-data-source.service";
import { filter, lastValueFrom, Subscription } from "rxjs";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver";
import { formatDate } from "@angular/common";
import { ExportingEvent } from "devextreme/ui/data_grid";
import { Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { NotificationType } from "../../../references/references";
import { AuthService } from "../../../../../settings/auth.service";
import { AllBonListService } from "../../../../../services/all-bons.service";
import DataSource from "devextreme/data/data_source";
import { CustomStoreService } from "../../../../../services/custom-store.service";
import { ArticleListService } from "../../../../../services/article.service";

const includeInfosCalculeesStorageKey = "bonListIncludeInfosCalculeesValue";
const includePartenaireInterneStorageKey = "bonListIncludePartenaireInterneValue";
@Component({
  selector: 'bon-list-tous-site-globale-datagrid',
  templateUrl: './bon-list-tous-site-globale-datagrid.component.html',
  styleUrls: ['./bon-list-tous-site-globale-datagrid.component.scss'],
})
export class BonListTousSiteGlobaleDatagridComponent implements OnInit,AfterContentInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  private subscriptions = new Subscription();
  @Input() fluxEtapeDataSource: FluxEtapeDto[];
  @Input() societeIsFondAutorise = false;
  @Input() currentPartenaireId: number;
  @Input() isMultiSite : boolean = false;
  mouvementTypeDataSource: LexiMouvementTypeDto[] = [];
  _includeInfosCalculees: boolean = false;
  _includePartenaireInterne: boolean = false;
  showFilter = false;
  messageCannotCreerBon = "";
  filtersFromForm: BonsCustomFilters;
  currentFiltreStatut = FluxStatutSimplifieEnum.ouvert;
  fluxStatutSimplifieEnum = FluxStatutSimplifieEnum;
  isCreerBonBtnEnabled = false;
  dropDownOptionsCreerBon = {
    height: '50vh'
  };
  files: File[] = [];
  showLoader = false;
  showImportPopup = false;
  selectedTypeFichierImportBon: TypeImportBon;
  typeFichierImportBon: any[] = [
    {id: TypeImportBon.stockAgenceParSousCaisse, label: "Stock agence par sous caisse"},
    // {id: TypeImportBon.import2, label: "Type d'import 2"}
  ]
  now: Date = new Date();
  articleDataSource: DataSource;
  _articleIdFiltre : number = 0;

  readonly btnStatuts: Array<{text: string, clef: FluxStatutSimplifieEnum, statuts: FluxStatut[]}> = [
    { text: "Ouvert", clef: FluxStatutSimplifieEnum.ouvert, statuts: [FluxStatut.new, FluxStatut.opened, FluxStatut.paused] },
    { text: "Fermé", clef: FluxStatutSimplifieEnum.ferme, statuts: [FluxStatut.closed] },
    { text: "Tous", clef: FluxStatutSimplifieEnum.tous, statuts: null }
  ];

  get includeInfosCalculees() { return this._includeInfosCalculees; }
  @Input() set includeInfosCalculees(value: boolean) {
    this._includeInfosCalculees = value;
    if (value != null) {
      this.setDataSource();
    }
  }

  get articleIdFiltre() { return this._articleIdFiltre; }
  set articleIdFiltre(value: number) {
    this._articleIdFiltre = value;
    if (value == null) {
      this._articleIdFiltre = 0;
    }
    this.setDataSource();
  }

  get inclurePartenaireInterne() { return this._includePartenaireInterne; }
  set inclurePartenaireInterne(value: boolean) {
    this._includePartenaireInterne = value;
    if (value != null) {
      this.setDataSource();
    }
  }

  showMarchandisesLegende = false;
  showreferencesLegende = false;

  @Input() bonStore: CustomStore;
  MouvementSens = MouvementSens;
  evenementTypes: EvenementTypeDto[];
  fluxStatutDataSource = [
    { id: FluxStatut.new, intitule: "Brouillon" },
    { id: FluxStatut.paused, intitule: "En attente" },
    { id: FluxStatut.opened, intitule: "En cours" },
    { id: FluxStatut.closed, intitule: "Finalisé" },
  ];

  constructor(
    private readonly bonListService: BonListService,
    private readonly evenementTypeLexiClient: EvenementTypesLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly router: Router,
    private readonly bonsLexiClient: BonsLexiClient,
    private readonly auth: AuthService,
    private readonly mouvementTypesLexiClient: LexiMouvementTypesLexiClient,
    private readonly allBonListService : AllBonListService,
    private readonly articleListService: ArticleListService,
    fluxLexiClient: FluxLexiClient
  ) {
    this.setDataSource();
    this.initArticleDataSource();
    fluxLexiClient.getEtapesByObjectType(ObjectType.bon).subscribe(e => {
      this.fluxEtapeDataSource = e;
    });
  }

  setDataSource() {
    const params = new Map()
      .set("includeInfosCalculees", this.includeInfosCalculees)
      .set("inclurePartenaireInterne", this.inclurePartenaireInterne)
      .set("articleId" , this.articleIdFiltre)
      .set("includeInterne" , this.isMultiSite);
    console.log(params)

    if (this.isMultiSite) {
      this.bonStore = this.dxDataSourceService.getCustomStore(this.allBonListService, params);
    }
    else {
      this.bonStore = this.dxDataSourceService.getCustomStore(this.bonListService, params);
    }

  }

  async ngOnInit() {
    await this.setEvenementTypes();
    this.initFiltres();
    this.includeInfosCalculees = localStorage.getItem(includeInfosCalculeesStorageKey) != null;
    this.dropDownOptionsCreerBon = { height: `${(this.mouvementTypeDataSource?.length ?? 1) * 40}px` };
    this.subscriptions.add(
      this.auth.currentSiteId$.pipe(
        filter((s: number) => s != null)
      ).subscribe(async () => {
        await this.setMouvementTypes();
      })
    );

    this.subscriptions.add(
      this.auth.currentUser$.subscribe(user => {
        if (user) {
          this.setCreerBonPermission();
        }
      })
    )

  }

  initArticleDataSource() {
    this.articleDataSource = new DataSource({
      key: 'id',
      paginate: true,
      pageSize: 10,
      store: new CustomStoreService(this.articleListService).getSelectBoxCustomStore()
    });
  }

  initFiltres() {
    this.filtersFromForm = {
      id: null,
      intitule : null,
      debutOperation: null,
      finOperation: null,
    };
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterContentInit(): void {
      this.onStatutSelected(FluxStatutSimplifieEnum.ouvert);
  }

  async setEvenementTypes() {
    const evenementTypes = await lastValueFrom(this.evenementTypeLexiClient.getAll());
    this.evenementTypes = evenementTypes.sort((a, b) => a.intitule && b.intitule ? a.intitule.localeCompare(b.intitule) : 0);
  }

  refreshDataGrid() {
    this.dataGrid?.instance.refresh();
  }

  clearDatagridFilters() {
    this.dataGrid.instance.clearFilter();
  }

  filterColumnHeader(column: string, values: any) {
    this.dataGrid.instance.columnOption(column, 'filterType', 'include');
    this.dataGrid.instance.columnOption(column, 'filterValues', values);
    this.refreshDataGrid();
  }

  resetFilterColumnHeader(column: string) {
    this.dataGrid.instance.columnOption(column, 'selectedFilterOperation', null);
    this.dataGrid.instance.columnOption(column, 'filterValues', null);
    this.refreshDataGrid();
  }

  filterColumn(column: string, filterOperation: string, value: any) {
    this.dataGrid?.instance.columnOption(column, 'selectedFilterOperation', filterOperation);
    this.dataGrid?.instance.columnOption(column, 'filterValue', value);
    this.refreshDataGrid();
  }

  onStatutSelected(statutChoisi: FluxStatutSimplifieEnum) {
    this.currentFiltreStatut = statutChoisi;
    if (statutChoisi == FluxStatutSimplifieEnum.tous) {
      this.resetFilterColumnHeader('fluxStatut');
    } else {
      const statuts = this.btnStatuts.find(x => x.clef == statutChoisi).statuts;
      this.filterColumnHeader('fluxStatut', statuts);
    }
  }

  customizeText(cellInfo: { valueText: string }) {
    return cellInfo?.valueText ? cellInfo.valueText : "—";
  }

  getEtapeLogo(evenementTypeId: number) {
    return evenementTypeId
      ? 'data:image/png;base64,' + this.fluxEtapeDataSource.find(x => x.evenementTypeId == evenementTypeId)?.evenementTypeLogo
      : "assets/images/Discount.png";
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Bons');

    exportDataGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {

          // Marchandises
          if (gridCell.column.caption === 'Marchandises' && gridCell.value == null) {
            excelCell.value = `${gridCell.data.nombreMarchandisesEncours ?? 0} / ${gridCell.data.nombreMarchandisesMouvementees ?? 0} / ${gridCell.data.nombreMarchandises ?? 0}`;
          }

          // Références
          if (gridCell.column.caption === 'Réf. mouvementées' && gridCell.value == null) {
            excelCell.value = `${gridCell.data.nombreReferencesMouvementees ?? 0} / ${gridCell.data.nombreReferences ?? 0}`;
          }

          // Source
          if (gridCell.column.caption === 'Source' && gridCell.value == null) {
            excelCell.value = this.currentPartenaireId == gridCell.data.partenaireSourceId
              ? gridCell.data.sourceStockageIntitule
              : gridCell.data.partenaireSourceIntitule;
          }

          // Destination
          if (gridCell.column.caption === 'Destination' && gridCell.value == null) {
            excelCell.value = this.currentPartenaireId == gridCell.data.partenaireDestinationId
              ? gridCell.data.destinationStockageIntitule
              : gridCell.data.partenaireDestinationIntitule;
          }
        }
      },
    }).then(() => {
      const date = formatDate((new Date), "yyyyMMdd-HHmm", "fr-FR");
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${date}-liste-des-bons.xlsx`);
      });
    });
  }
  storeIncludeInfosCalculeesValue() {
    if (this.includeInfosCalculees) {
      localStorage.setItem(includeInfosCalculeesStorageKey, "true");
    }
    else {
      localStorage.removeItem(includeInfosCalculeesStorageKey);
    }
  }

  onToggleMenu() {
    this.showFilter = !this.showFilter;
    this.refreshDataGrid();
  }

  async resetFilters() {
    this.initFiltres();
    const currentDataSource = this.dataGrid.instance.getDataSource();
    currentDataSource.filter(null);
    await currentDataSource.reload();
  }

  async importBon(e: { itemData: LexiMouvementTypeDto }) {
    this.showLoader = true;
    this.bonsLexiClient.importFileToCreateDraft(this.selectedTypeFichierImportBon, e.itemData.id, this.files[0])
    var result: ImportBonResult = await lastValueFrom(this.bonsLexiClient.importFileToCreateDraft(this.selectedTypeFichierImportBon, e.itemData.id, this.files[0]));

    notify({closeOnClick: true, message: result.nombreBonsCrees }, NotificationType.Success, 3500);

    if (result.erreurs.length > 0) {
      result.erreurs.forEach(elem => {
        notify({closeOnClick: true, message: elem }, NotificationType.Error, 3500);
      });
    }

    this.refreshDataGrid();
    this.onCloseImportPopup();
  }

  onTypeFichierImportValueChanged = (e: { value: TypeImportBon }) => {
    if (e.value) {
      this.selectedTypeFichierImportBon = e.value;
    }
  }

  onCloseImportPopup() {
    this.selectedTypeFichierImportBon = null;
    this.showLoader = false;
    this.showImportPopup = false;
    this.files = [];
  }
  async setMouvementTypes() {
    const mouvementTypes = await lastValueFrom(this.mouvementTypesLexiClient.getMouvementType(DocumentType.stock, false, true, ObjectType.bon, true));
    this.mouvementTypeDataSource = mouvementTypes.filter(x => x.autorisationBonSeul && !x.bloque);
    this.setCreerBonPermission();
  }

  private setCreerBonPermission() {
    const canGererBons = this.auth.securityUserisGrantedWith(Permissions.canGererBons);

    if (this.mouvementTypeDataSource?.length > 0) {
      this.isCreerBonBtnEnabled = canGererBons;
      this.messageCannotCreerBon = canGererBons ? "" : "Vous n'avez pas la permission pour créer des bons";
    } else {
      this.isCreerBonBtnEnabled = false;
      this.messageCannotCreerBon = this.auth.securityUser?.isRoot
        ? "Activez l'option 'AutorisationBonSeul' sur les types de mouvements que vous voulez pouvoir créer directement"
        : "Aucun type de mouvement n'est disponible, il n'est pas possible de créer de bon sans passer par un document logistique";
    }
  }

  getBonsSelonFiltres = async () => {
    if (this.bonStore == null || this.dataGrid?.instance == null) return;

    const currentDataSource = this.dataGrid?.instance.getDataSource();
    if (!currentDataSource) return;

    const customFilters = this.getCustomFilters();

    currentDataSource.filter(customFilters);

    currentDataSource.reload();
  }


  getCustomFilters(): Array<any> {
    const filtersToSend = [];

    console.log(this.filtersFromForm)

    // Si siteId = null, on ne filtre pas par site
    if (this.filtersFromForm.id != null)
      filtersToSend.push(["id", "=", this.filtersFromForm.id]);

    // Date début / Date de fin : dateOperation
    if (this.filtersFromForm.intitule != null) {
      filtersToSend.push(["intitule", "contains", this.filtersFromForm.intitule]);
    }

    if (this.filtersFromForm.debutOperation != null) {
      filtersToSend.push(["dateOperation", ">=", this.filtersFromForm.debutOperation.toISOString()]);
    }

    if (this.filtersFromForm.finOperation != null) {
      this.pushAndIfLenNotZero(filtersToSend);
      filtersToSend.push(["dateOperation", "<=", this.filtersFromForm.finOperation.toISOString()]);
    }

    return filtersToSend.length > 0 ? filtersToSend : null;
  }

  pushAndIfLenNotZero(filtres: Array<any>) {
    if (filtres.length > 0) filtres.push("and");
  }

  onCreateNewBon(e: { itemData: LexiMouvementTypeDto }) {
    this.router.navigate([`/bon/nouveau`], { queryParams: { mouvementTypeCodeBo: e.itemData.codeBo } });
  }

}
enum FluxStatutSimplifieEnum {
  ouvert,
  ferme,
  tous
}
interface BonsCustomFilters {
  id : number,
  intitule : string,
  debutOperation?: Date,
  finOperation?: Date,
  articleId?: number,
}
