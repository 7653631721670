/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ArticleAPreparerFromBonsDto } from '../model/models';
import { BonACreerDto } from '../model/models';
import { BonAvecReferenceEtPaireDto } from '../model/models';
import { BonDto } from '../model/models';
import { BonLigneDto } from '../model/models';
import { CopieImageStockType } from '../model/models';
import { DisponibiliteMarchandiseDto } from '../model/models';
import { FluxStatut } from '../model/models';
import { FluxUpdateDto } from '../model/models';
import { GetBonByDocumentLogistiqueResultDto } from '../model/models';
import { GetStockResult } from '../model/models';
import { GroupingInfo } from '../model/models';
import { ImportBonResult } from '../model/models';
import { LoadResult } from '../model/models';
import { NumeroSerieDto } from '../model/models';
import { NumeroSerieUpdateDto } from '../model/models';
import { ReserverQuantitesResult } from '../model/models';
import { SortingInfo } from '../model/models';
import { SummaryInfo } from '../model/models';
import { TypeImportBon } from '../model/models';
import { UpdateBonDto } from '../model/models';
import { UpdateBonQuantitesAvecReliquatDto } from '../model/models';
import { UpdateBonQuantitesDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BonsLexiClient {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param id 
     * @param typeCopie 
     * @param chercherDansSource 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copierImageStock(id: number, typeCopie: CopieImageStockType, chercherDansSource?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public copierImageStock(id: number, typeCopie: CopieImageStockType, chercherDansSource?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public copierImageStock(id: number, typeCopie: CopieImageStockType, chercherDansSource?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public copierImageStock(id: number, typeCopie: CopieImageStockType, chercherDansSource?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling copierImageStock.');
        }
        if (typeCopie === null || typeCopie === undefined) {
            throw new Error('Required parameter typeCopie was null or undefined when calling copierImageStock.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (chercherDansSource !== undefined && chercherDansSource !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>chercherDansSource, 'chercherDansSource');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.put(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}/image-stock/${encodeURIComponent(String(typeCopie))}`,
            null,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param bonACreerDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public creer(bonACreerDto: BonACreerDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public creer(bonACreerDto: BonACreerDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public creer(bonACreerDto: BonACreerDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public creer(bonACreerDto: BonACreerDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (bonACreerDto === null || bonACreerDto === undefined) {
            throw new Error('Required parameter bonACreerDto was null or undefined when calling creer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/api/Bons`,
            bonACreerDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param lieuStockageId 
     * @param fluxStatuts 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllByLieuStockageEtFluxStatuts(lieuStockageId: number, fluxStatuts?: Array<FluxStatut>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BonAvecReferenceEtPaireDto>>;
    public getAllByLieuStockageEtFluxStatuts(lieuStockageId: number, fluxStatuts?: Array<FluxStatut>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BonAvecReferenceEtPaireDto>>>;
    public getAllByLieuStockageEtFluxStatuts(lieuStockageId: number, fluxStatuts?: Array<FluxStatut>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BonAvecReferenceEtPaireDto>>>;
    public getAllByLieuStockageEtFluxStatuts(lieuStockageId: number, fluxStatuts?: Array<FluxStatut>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (lieuStockageId === null || lieuStockageId === undefined) {
            throw new Error('Required parameter lieuStockageId was null or undefined when calling getAllByLieuStockageEtFluxStatuts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fluxStatuts) {
            fluxStatuts.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'fluxStatuts');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BonAvecReferenceEtPaireDto>>(`${this.configuration.basePath}/api/lieux/${encodeURIComponent(String(lieuStockageId))}/bons`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param lieuStockageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBonAvecReferenceEtPaireById(id: number, lieuStockageId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BonAvecReferenceEtPaireDto>;
    public getBonAvecReferenceEtPaireById(id: number, lieuStockageId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BonAvecReferenceEtPaireDto>>;
    public getBonAvecReferenceEtPaireById(id: number, lieuStockageId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BonAvecReferenceEtPaireDto>>;
    public getBonAvecReferenceEtPaireById(id: number, lieuStockageId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBonAvecReferenceEtPaireById.');
        }
        if (lieuStockageId === null || lieuStockageId === undefined) {
            throw new Error('Required parameter lieuStockageId was null or undefined when calling getBonAvecReferenceEtPaireById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BonAvecReferenceEtPaireDto>(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}/lieu-stockage/${encodeURIComponent(String(lieuStockageId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentLogistiqueId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByDocumentLogistiqueId(documentLogistiqueId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GetBonByDocumentLogistiqueResultDto>>;
    public getByDocumentLogistiqueId(documentLogistiqueId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GetBonByDocumentLogistiqueResultDto>>>;
    public getByDocumentLogistiqueId(documentLogistiqueId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GetBonByDocumentLogistiqueResultDto>>>;
    public getByDocumentLogistiqueId(documentLogistiqueId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentLogistiqueId !== undefined && documentLogistiqueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentLogistiqueId, 'documentLogistiqueId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<GetBonByDocumentLogistiqueResultDto>>(`${this.configuration.basePath}/api/Bons/document-logistique`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BonDto>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BonDto>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BonDto>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BonDto>(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param bonId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDisponibiliteMarchandise(bonId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DisponibiliteMarchandiseDto>>;
    public getDisponibiliteMarchandise(bonId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DisponibiliteMarchandiseDto>>>;
    public getDisponibiliteMarchandise(bonId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DisponibiliteMarchandiseDto>>>;
    public getDisponibiliteMarchandise(bonId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (bonId !== undefined && bonId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bonId, 'bonId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DisponibiliteMarchandiseDto>>(`${this.configuration.basePath}/api/Bons/disponibilite-marchandises`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEdition(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public getEdition(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public getEdition(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public getEdition(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEdition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}/edition`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLignes(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BonLigneDto>>;
    public getLignes(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BonLigneDto>>>;
    public getLignes(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BonLigneDto>>>;
    public getLignes(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getLignes.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BonLigneDto>>(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}/lignes`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param lieuStockageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListeArticleAPreparer(lieuStockageId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ArticleAPreparerFromBonsDto>>;
    public getListeArticleAPreparer(lieuStockageId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ArticleAPreparerFromBonsDto>>>;
    public getListeArticleAPreparer(lieuStockageId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ArticleAPreparerFromBonsDto>>>;
    public getListeArticleAPreparer(lieuStockageId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lieuStockageId !== undefined && lieuStockageId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lieuStockageId, 'lieuStockageId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ArticleAPreparerFromBonsDto>>(`${this.configuration.basePath}/api/Bons/liste-article-a-preparer`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNumeroSerie(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<NumeroSerieDto>>;
    public getNumeroSerie(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<NumeroSerieDto>>>;
    public getNumeroSerie(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<NumeroSerieDto>>>;
    public getNumeroSerie(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getNumeroSerie.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<NumeroSerieDto>>(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}/numero-de-serie`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param articleId 
     * @param lieuStockageId 
     * @param bonLigneIdAExclure 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStockResultByLieuStockageAndArticle(articleId: number, lieuStockageId: number, bonLigneIdAExclure?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GetStockResult>;
    public getStockResultByLieuStockageAndArticle(articleId: number, lieuStockageId: number, bonLigneIdAExclure?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GetStockResult>>;
    public getStockResultByLieuStockageAndArticle(articleId: number, lieuStockageId: number, bonLigneIdAExclure?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GetStockResult>>;
    public getStockResultByLieuStockageAndArticle(articleId: number, lieuStockageId: number, bonLigneIdAExclure?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling getStockResultByLieuStockageAndArticle.');
        }
        if (lieuStockageId === null || lieuStockageId === undefined) {
            throw new Error('Required parameter lieuStockageId was null or undefined when calling getStockResultByLieuStockageAndArticle.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (bonLigneIdAExclure !== undefined && bonLigneIdAExclure !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bonLigneIdAExclure, 'bonLigneIdAExclure');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<GetStockResult>(`${this.configuration.basePath}/api/Bons/stock/article/${encodeURIComponent(String(articleId))}/lieu-stockage/${encodeURIComponent(String(lieuStockageId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param lieuStockageId 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStockResultByLieuStockageAndArticleList(lieuStockageId: number, requestBody: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<GetStockResult>>;
    public getStockResultByLieuStockageAndArticleList(lieuStockageId: number, requestBody: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<GetStockResult>>>;
    public getStockResultByLieuStockageAndArticleList(lieuStockageId: number, requestBody: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<GetStockResult>>>;
    public getStockResultByLieuStockageAndArticleList(lieuStockageId: number, requestBody: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (lieuStockageId === null || lieuStockageId === undefined) {
            throw new Error('Required parameter lieuStockageId was null or undefined when calling getStockResultByLieuStockageAndArticleList.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling getStockResultByLieuStockageAndArticleList.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<GetStockResult>>(`${this.configuration.basePath}/api/Bons/stock/lieu-stockage/${encodeURIComponent(String(lieuStockageId))}`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param type 
     * @param mouvementTypeId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importFileToCreateDraft(type?: TypeImportBon, mouvementTypeId?: number, file?: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ImportBonResult>;
    public importFileToCreateDraft(type?: TypeImportBon, mouvementTypeId?: number, file?: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ImportBonResult>>;
    public importFileToCreateDraft(type?: TypeImportBon, mouvementTypeId?: number, file?: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ImportBonResult>>;
    public importFileToCreateDraft(type?: TypeImportBon, mouvementTypeId?: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }
        if (mouvementTypeId !== undefined && mouvementTypeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>mouvementTypeId, 'mouvementTypeId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ImportBonResult>(`${this.configuration.basePath}/api/Bons/import/brouillon`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requireTotalCount A flag indicating whether the total number of data objects is required.
     * @param requireGroupCount A flag indicating whether the number of top-level groups is required.
     * @param isCountQuery A flag indicating whether the current query is made to get the total number of data objects.
     * @param isSummaryQuery 
     * @param skip The number of data objects to be skipped from the start of the resulting set.
     * @param take The number of data objects to be loaded.
     * @param sort A sort expression.
     * @param group A group expression.
     * @param filter A filter expression.
     * @param totalSummary A total summary expression.
     * @param groupSummary A group summary expression.
     * @param select A select expression.
     * @param preSelect An array of data fields that limits the Select expression. The applied select expression is the intersection of PreSelect and Select.
     * @param remoteSelect A flag that indicates whether the LINQ provider should execute the select expression. If set to false, the select operation is performed in memory.
     * @param remoteGrouping A flag that indicates whether the LINQ provider should execute grouping. If set to false, the entire dataset is loaded and grouped in memory.
     * @param expandLinqSumType 
     * @param primaryKey An array of primary keys.
     * @param defaultSort The data field to be used for sorting by default.
     * @param stringToLower A flag that indicates whether filter expressions should include a ToLower() call that makes string comparison case-insensitive. Defaults to true for LINQ to Objects, false for any other provider.
     * @param paginateViaPrimaryKey If this flag is enabled, keys and data are loaded via separate queries. This may result in a more efficient SQL execution plan.
     * @param sortByPrimaryKey 
     * @param allowAsyncOverSync 
     * @param articleId 
     * @param includeInfosCalculees 
     * @param inclurePartenaireInterne 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rechercheAllDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, articleId?: number, includeInfosCalculees?: boolean, inclurePartenaireInterne?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LoadResult>;
    public rechercheAllDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, articleId?: number, includeInfosCalculees?: boolean, inclurePartenaireInterne?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LoadResult>>;
    public rechercheAllDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, articleId?: number, includeInfosCalculees?: boolean, inclurePartenaireInterne?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LoadResult>>;
    public rechercheAllDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, articleId?: number, includeInfosCalculees?: boolean, inclurePartenaireInterne?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (requireTotalCount !== undefined && requireTotalCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireTotalCount, 'RequireTotalCount');
        }
        if (requireGroupCount !== undefined && requireGroupCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireGroupCount, 'RequireGroupCount');
        }
        if (isCountQuery !== undefined && isCountQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isCountQuery, 'IsCountQuery');
        }
        if (isSummaryQuery !== undefined && isSummaryQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isSummaryQuery, 'IsSummaryQuery');
        }
        if (skip !== undefined && skip !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>skip, 'Skip');
        }
        if (take !== undefined && take !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>take, 'Take');
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Sort');
            })
        }
        if (group) {
            group.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Group');
            })
        }
        if (filter) {
            filter.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Filter');
            })
        }
        if (totalSummary) {
            totalSummary.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'TotalSummary');
            })
        }
        if (groupSummary) {
            groupSummary.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'GroupSummary');
            })
        }
        if (select) {
            select.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Select');
            })
        }
        if (preSelect) {
            preSelect.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'PreSelect');
            })
        }
        if (remoteSelect !== undefined && remoteSelect !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remoteSelect, 'RemoteSelect');
        }
        if (remoteGrouping !== undefined && remoteGrouping !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remoteGrouping, 'RemoteGrouping');
        }
        if (expandLinqSumType !== undefined && expandLinqSumType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>expandLinqSumType, 'ExpandLinqSumType');
        }
        if (primaryKey) {
            primaryKey.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'PrimaryKey');
            })
        }
        if (defaultSort !== undefined && defaultSort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>defaultSort, 'DefaultSort');
        }
        if (stringToLower !== undefined && stringToLower !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stringToLower, 'StringToLower');
        }
        if (paginateViaPrimaryKey !== undefined && paginateViaPrimaryKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paginateViaPrimaryKey, 'PaginateViaPrimaryKey');
        }
        if (sortByPrimaryKey !== undefined && sortByPrimaryKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByPrimaryKey, 'SortByPrimaryKey');
        }
        if (allowAsyncOverSync !== undefined && allowAsyncOverSync !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAsyncOverSync, 'AllowAsyncOverSync');
        }
        if (articleId !== undefined && articleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>articleId, 'articleId');
        }
        if (includeInfosCalculees !== undefined && includeInfosCalculees !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeInfosCalculees, 'includeInfosCalculees');
        }
        if (inclurePartenaireInterne !== undefined && inclurePartenaireInterne !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>inclurePartenaireInterne, 'inclurePartenaireInterne');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LoadResult>(`${this.configuration.basePath}/api/Bons/recherche-devexpress-all`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requireTotalCount A flag indicating whether the total number of data objects is required.
     * @param requireGroupCount A flag indicating whether the number of top-level groups is required.
     * @param isCountQuery A flag indicating whether the current query is made to get the total number of data objects.
     * @param isSummaryQuery 
     * @param skip The number of data objects to be skipped from the start of the resulting set.
     * @param take The number of data objects to be loaded.
     * @param sort A sort expression.
     * @param group A group expression.
     * @param filter A filter expression.
     * @param totalSummary A total summary expression.
     * @param groupSummary A group summary expression.
     * @param select A select expression.
     * @param preSelect An array of data fields that limits the Select expression. The applied select expression is the intersection of PreSelect and Select.
     * @param remoteSelect A flag that indicates whether the LINQ provider should execute the select expression. If set to false, the select operation is performed in memory.
     * @param remoteGrouping A flag that indicates whether the LINQ provider should execute grouping. If set to false, the entire dataset is loaded and grouped in memory.
     * @param expandLinqSumType 
     * @param primaryKey An array of primary keys.
     * @param defaultSort The data field to be used for sorting by default.
     * @param stringToLower A flag that indicates whether filter expressions should include a ToLower() call that makes string comparison case-insensitive. Defaults to true for LINQ to Objects, false for any other provider.
     * @param paginateViaPrimaryKey If this flag is enabled, keys and data are loaded via separate queries. This may result in a more efficient SQL execution plan.
     * @param sortByPrimaryKey 
     * @param allowAsyncOverSync 
     * @param lieuStockageId 
     * @param articleId 
     * @param includeInfosCalculees 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, lieuStockageId?: number, articleId?: number, includeInfosCalculees?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<LoadResult>;
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, lieuStockageId?: number, articleId?: number, includeInfosCalculees?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<LoadResult>>;
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, lieuStockageId?: number, articleId?: number, includeInfosCalculees?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<LoadResult>>;
    public rechercheDx(requireTotalCount?: boolean, requireGroupCount?: boolean, isCountQuery?: boolean, isSummaryQuery?: boolean, skip?: number, take?: number, sort?: Array<SortingInfo>, group?: Array<GroupingInfo>, filter?: Array<any>, totalSummary?: Array<SummaryInfo>, groupSummary?: Array<SummaryInfo>, select?: Array<string>, preSelect?: Array<string>, remoteSelect?: boolean, remoteGrouping?: boolean, expandLinqSumType?: boolean, primaryKey?: Array<string>, defaultSort?: string, stringToLower?: boolean, paginateViaPrimaryKey?: boolean, sortByPrimaryKey?: boolean, allowAsyncOverSync?: boolean, lieuStockageId?: number, articleId?: number, includeInfosCalculees?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (requireTotalCount !== undefined && requireTotalCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireTotalCount, 'RequireTotalCount');
        }
        if (requireGroupCount !== undefined && requireGroupCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requireGroupCount, 'RequireGroupCount');
        }
        if (isCountQuery !== undefined && isCountQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isCountQuery, 'IsCountQuery');
        }
        if (isSummaryQuery !== undefined && isSummaryQuery !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isSummaryQuery, 'IsSummaryQuery');
        }
        if (skip !== undefined && skip !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>skip, 'Skip');
        }
        if (take !== undefined && take !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>take, 'Take');
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Sort');
            })
        }
        if (group) {
            group.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Group');
            })
        }
        if (filter) {
            filter.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Filter');
            })
        }
        if (totalSummary) {
            totalSummary.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'TotalSummary');
            })
        }
        if (groupSummary) {
            groupSummary.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'GroupSummary');
            })
        }
        if (select) {
            select.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'Select');
            })
        }
        if (preSelect) {
            preSelect.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'PreSelect');
            })
        }
        if (remoteSelect !== undefined && remoteSelect !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remoteSelect, 'RemoteSelect');
        }
        if (remoteGrouping !== undefined && remoteGrouping !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remoteGrouping, 'RemoteGrouping');
        }
        if (expandLinqSumType !== undefined && expandLinqSumType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>expandLinqSumType, 'ExpandLinqSumType');
        }
        if (primaryKey) {
            primaryKey.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'PrimaryKey');
            })
        }
        if (defaultSort !== undefined && defaultSort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>defaultSort, 'DefaultSort');
        }
        if (stringToLower !== undefined && stringToLower !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stringToLower, 'StringToLower');
        }
        if (paginateViaPrimaryKey !== undefined && paginateViaPrimaryKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>paginateViaPrimaryKey, 'PaginateViaPrimaryKey');
        }
        if (sortByPrimaryKey !== undefined && sortByPrimaryKey !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByPrimaryKey, 'SortByPrimaryKey');
        }
        if (allowAsyncOverSync !== undefined && allowAsyncOverSync !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>allowAsyncOverSync, 'AllowAsyncOverSync');
        }
        if (lieuStockageId !== undefined && lieuStockageId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lieuStockageId, 'lieuStockageId');
        }
        if (articleId !== undefined && articleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>articleId, 'articleId');
        }
        if (includeInfosCalculees !== undefined && includeInfosCalculees !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>includeInfosCalculees, 'includeInfosCalculees');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<LoadResult>(`${this.configuration.basePath}/api/Bons/recherche-devexpress`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fluxUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reserver(fluxUpdateDto: FluxUpdateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ReserverQuantitesResult>;
    public reserver(fluxUpdateDto: FluxUpdateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ReserverQuantitesResult>>;
    public reserver(fluxUpdateDto: FluxUpdateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ReserverQuantitesResult>>;
    public reserver(fluxUpdateDto: FluxUpdateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (fluxUpdateDto === null || fluxUpdateDto === undefined) {
            throw new Error('Required parameter fluxUpdateDto was null or undefined when calling reserver.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<ReserverQuantitesResult>(`${this.configuration.basePath}/api/Bons/quantites-reservees`,
            fluxUpdateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param numeroSerieUpdateDto 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateArticleNumerosSerie(id: number, numeroSerieUpdateDto: Array<NumeroSerieUpdateDto>, articleId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public updateArticleNumerosSerie(id: number, numeroSerieUpdateDto: Array<NumeroSerieUpdateDto>, articleId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public updateArticleNumerosSerie(id: number, numeroSerieUpdateDto: Array<NumeroSerieUpdateDto>, articleId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public updateArticleNumerosSerie(id: number, numeroSerieUpdateDto: Array<NumeroSerieUpdateDto>, articleId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateArticleNumerosSerie.');
        }
        if (numeroSerieUpdateDto === null || numeroSerieUpdateDto === undefined) {
            throw new Error('Required parameter numeroSerieUpdateDto was null or undefined when calling updateArticleNumerosSerie.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (articleId !== undefined && articleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>articleId, 'articleId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/api/Bons/${encodeURIComponent(String(id))}/numero-de-serie-update`,
            numeroSerieUpdateDto,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateBonDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBon(updateBonDto: UpdateBonDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public updateBon(updateBonDto: UpdateBonDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public updateBon(updateBonDto: UpdateBonDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public updateBon(updateBonDto: UpdateBonDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (updateBonDto === null || updateBonDto === undefined) {
            throw new Error('Required parameter updateBonDto was null or undefined when calling updateBon.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch(`${this.configuration.basePath}/api/Bons`,
            updateBonDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateBonQuantitesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuantitesThenFlux(updateBonQuantitesDto: UpdateBonQuantitesDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<Blob>;
    public updateQuantitesThenFlux(updateBonQuantitesDto: UpdateBonQuantitesDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpResponse<Blob>>;
    public updateQuantitesThenFlux(updateBonQuantitesDto: UpdateBonQuantitesDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<HttpEvent<Blob>>;
    public updateQuantitesThenFlux(updateBonQuantitesDto: UpdateBonQuantitesDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream'}): Observable<any> {
        if (updateBonQuantitesDto === null || updateBonQuantitesDto === undefined) {
            throw new Error('Required parameter updateBonQuantitesDto was null or undefined when calling updateQuantitesThenFlux.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch(`${this.configuration.basePath}/api/Bons/quantites`,
            updateBonQuantitesDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateBonQuantitesAvecReliquatDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuantitesThenFluxAvecReliquat(updateBonQuantitesAvecReliquatDto: UpdateBonQuantitesAvecReliquatDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<number>;
    public updateQuantitesThenFluxAvecReliquat(updateBonQuantitesAvecReliquatDto: UpdateBonQuantitesAvecReliquatDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<number>>;
    public updateQuantitesThenFluxAvecReliquat(updateBonQuantitesAvecReliquatDto: UpdateBonQuantitesAvecReliquatDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<number>>;
    public updateQuantitesThenFluxAvecReliquat(updateBonQuantitesAvecReliquatDto: UpdateBonQuantitesAvecReliquatDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (updateBonQuantitesAvecReliquatDto === null || updateBonQuantitesAvecReliquatDto === undefined) {
            throw new Error('Required parameter updateBonQuantitesAvecReliquatDto was null or undefined when calling updateQuantitesThenFluxAvecReliquat.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (Bearer) required
        credential = this.configuration.lookupCredential('Bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/api/Bons/reliquat`,
            updateBonQuantitesAvecReliquatDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
