import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { QUERY_KEY_RETURN_URL } from "./constants";
import { FeatureTag, FeatureTagService } from "../../../../../lexi-angular/src/app/shared/services/feature-tag.service";
import { AllBonListComponent } from "../../../../../lexi-angular/src/app/modules/shared/bon/all-bon-list/all-bon-list.component";

@Injectable({
  providedIn: 'root',
})
export class FeatureTagsGuard {

  constructor(
    private readonly featureTagService : FeatureTagService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    switch (route.routeConfig.component) {
      case AllBonListComponent :
        return this.featureTagService.isFeatureEnabled(FeatureTag.VOIR_BONS_SITES_PERMIS);
    }
   return false;
  }

}
