<dx-form [formData]="voyageFretLocalSettings" class="form last plain-styled-form"  [colCount]="3" [readOnly]="formIsReadOnly">
  <dxi-item dataField="integrationAutomatiqueEnabled" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Activer l\'intégration automatique des demandes '}" [colSpan]="colCount"></dxi-item>

  <!-- TODO : A afficher lorsque les conditions poids/volume max seront pris en compte -->
  <!-- <dxi-item dataField="poidsMax" [label]="{text: 'Poids max. (T)'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 6 } }" [colSpan]="colSpan"></dxi-item>
  <dxi-item dataField="volumeMax" [label]="{text: 'Volume max. (m3)'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 } }" [colSpan]="colSpan"></dxi-item> -->
  <dxi-item dataField="minutesAvantDepart" [label]="{text: 'Limite de dépôt avant le départ (Minutes)'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }" [colSpan]="colSpan"></dxi-item>

  <!-- StatutAcceptationPourClientCredit -->
  <dxi-item *ngIf="IsFicheVoyage" dataField="forcerStatutAcceptationPourClientCredit" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Forcer'}" [colSpan]="colSpan"></dxi-item>
  <dxi-item
    dataField="statutAcceptationPourClientCredit"
    [label]="{text: 'Statut des demandes crédit'}"
    [colSpan]="colSpan"
    editorType="dxSelectBox"
    [editorOptions]="{
      dataSource: statutsRevatuaAcceptation,
      searchEnabled: true,
      displayExpr: 'intitule',
      valueExpr: 'id',
      searchExpr: ['intitule'],
      showDataBeforeSearch: true,
      placeholder: 'Manuel'
    }"
  ></dxi-item>

  <!-- StatutAcceptationPourClientComptant -->
  <dxi-item *ngIf="IsFicheVoyage" dataField="forcerStatutAcceptationPourClientComptant" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Forcer'}" [colSpan]="colSpan"></dxi-item>
  <dxi-item
    dataField="statutAcceptationPourClientComptant"
    [label]="{text: 'Statuts des demandes comptants'}"
    [colSpan]="colSpan"
    editorType="dxSelectBox"
    [editorOptions]="{
      dataSource: statutsRevatuaAcceptation,
      searchEnabled: true,
      displayExpr: 'intitule',
      valueExpr: 'id',
      searchExpr: ['intitule'],
      showDataBeforeSearch: true,
      placeholder: 'Manuel'
    }"
  ></dxi-item>

  <!-- StatutSiPayeurBloque -->
  <dxi-item *ngIf="IsFicheVoyage" dataField="forcerStatutSiPayeurBloque" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Forcer'}" [colSpan]="colSpan"></dxi-item>
  <dxi-item
    id="statutSiPayeurBloque"
    dataField="statutSiPayeurBloque"
    [label]="{text: 'Statut si payeur bloqué'}"
    [colSpan]="colSpan"
    editorType="dxSelectBox"
    [editorOptions]="{
      dataSource: statutsRevatuaPayeurBloque,
      searchEnabled: true,
      displayExpr: 'intitule',
      valueExpr: 'id',
      searchExpr: ['intitule'],
      showDataBeforeSearch: true,
      placeholder: 'Manuel'
    }"
  ></dxi-item>

  <dxi-item
    *ngIf="voyageFretLocalSettings?.statutSiPayeurBloque == StatutRevatua.demandERefusee"
    dataField="statutSiPayeurBloqueIdMotifRefus"
    [label]="{text: 'Motif en cas de refus'}"
    [colSpan]="colSpan"
    editorType="dxSelectBox"
    [editorOptions]="{
      dataSource: motifRefusDemande,
      searchEnabled: true,
      displayExpr: 'libelle',
      valueExpr: 'idRevatua',
      searchExpr: ['libelle'],
      showDataBeforeSearch: true
    }"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-item>

  <dxi-item *ngIf="voyageFretLocalSettings?.statutSiPayeurBloque == StatutRevatua.demandERefusee"
    dataField="statutSiPayeurBloqueCommentaireEnCasDeRefus" [label]="{ text: 'Commentaire en cas de refus' }"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-item>

</dx-form>

<!-- #region Tooltips -->
<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='minutesAvantDepart']">
  <div *dxTemplate="let data = data of 'content'">
    Si le départ est à moins de X minutes, il n'y aura plus d'intégration automatique pour ce voyage.
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='forcerStatutAcceptationPourClientCredit']">
  <div *dxTemplate="let data = data of 'content'">
    Forcer le statut d'acceptation par défaut pour les clients à crédit, le paramètre sur la fiche client ne sera pas pris en compte.
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='statutAcceptationPourClientCredit']">
  <div *dxTemplate="let data = data of 'content'">
    {{ getDescription("statutAcceptationPourClientCredit") }}
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='forcerStatutAcceptationPourClientComptant']">
  <div *dxTemplate="let data = data of 'content'">
    Forcer le statut d'acceptation par défaut pour les clients comptants, le paramètre sur la fiche client ne sera pas pris en compte.
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='statutAcceptationPourClientComptant']">
  <div *dxTemplate="let data = data of 'content'">
    {{ getDescription("statutAcceptationPourClientComptant") }}
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='forcerStatutSiPayeurBloque']">
  <div *dxTemplate="let data = data of 'content'">
    Forcer le "statut si payeur bloqué" pour les clients bloqués, le paramètre sur la fiche client ne sera pas pris en compte.
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='statutSiPayeurBloqueIdMotifRefus']">
  <div *dxTemplate="let data = data of 'content'">
    Le motif de refus obligatoire REVATUA à joindre à l'état "Demande refusée".
  </div>
</dx-tooltip>

<dx-tooltip showEvent="mouseenter" hideEvent="mouseleave" target="label[for*='statutSiPayeurBloqueCommentaireEnCasDeRefus']">
  <div *dxTemplate="let data = data of 'content'">
    Le commentaire obligatoire REVATUA à joindre à l'état "Demande refusée".
  </div>
</dx-tooltip>
<!-- #endregion -->