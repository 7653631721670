<div class="form-container">
  <!-- Entête -->
  <dx-box direction="row" width="100%" [height]="50" align="center" crossAlign="center">
    <!-- Bouton de retour -->
    <dxi-item class="back-button" [ratio]="1">
      <a routerLink="/articles">
        <dx-button stylingMode="text" icon="back"></dx-button>
      </a>
    </dxi-item>

    <!-- Libellé de l'article -->
    <dxi-item [ratio]="3">
      <h2 *ngIf="isCreation">Création d'un nouvel article</h2>
      <h3 *ngIf="!isCreation">{{ article?.codeBo }} - {{ article?.libelleLong }}</h3>
    </dxi-item>

    <!-- Boutons d'action -->
    <dxi-item [ratio]="1" [visible]="isCreation || isModificationEnCours">
      <div>
        <dx-button class="action-button" text="Enregistrer" type="success" icon="save" (onClick)="onUpdateArticle()"
          [visible]="!isCreation && isModificationEnCours"></dx-button>
        <dx-button class="action-button" text="Annuler" type="danger" icon="undo" (onClick)="onCancel()"
          [visible]="!isCreation && isModificationEnCours"></dx-button>
        <dx-button class="action-button" text="Créer" type="success" icon="save" (onClick)="onCreateNewArticle()"
          [visible]="isCreation"></dx-button>
      </div>
    </dxi-item>
    <dxi-item [ratio]="1" *ngIf="!isCreation">
      <div class="menu-button">
        <app-objecttype-edition-possible-list [objectType]="objectType.article" [objectId]="article?.id"
          [isDropDownButton]="true"></app-objecttype-edition-possible-list>
        <dx-drop-down-button [items]="actions" keyExpr="id" displayExpr="intitule" text="Action" icon="smalliconslayout"
          [dropDownOptions]="{ width: 120 }" [disabled]="isModificationEnCours || !isModificationAutorisee"
          (onItemClick)="onActionClick($event)" style="margin-left: 10px"></dx-drop-down-button>
      </div>
    </dxi-item>
  </dx-box>

  <div *ngIf="article && chargementInitialFait" style="
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 2em;
    ">
    <!-- Relation 1 à 1 ou 1 à n -->
    <div style="display: flex; flex-direction: column; gap: 2rem">
      <div class="carteArticle">
        <div style="
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 2em;
          ">
          <div class="loadingImage" *ngIf="photoPrincipaleId != null && isImageLoading"></div>
          <div *ngIf="photoPrincipaleId != null && !isImageLoading">
            <img [src]="imageSrc" class="photoPrincipale" />
          </div>
          <div *ngIf="photoPrincipaleId == null">
            <div id="dropzone-external" class="dx-button-mode-text dx-state-focused">
              <div class="align-icon">
                <i class="dx-icon-photo icon"></i>
              </div>
            </div>

            <dx-file-uploader dialogTrigger="#dropzone-external" dropZone="#dropzone-external" [multiple]="false"
              [allowedFileExtensions]="['.jpg', '.jpeg', '.png']" accept="image/*" uploadMode="useForm"
              [(value)]="addedPhotos" [visible]="false" (onValueChanged)="addNewImage()"></dx-file-uploader>
          </div>
          <div style="display: flex; flex-direction: column; gap: 2rem">
            <div *ngFor="let classification of classifications; let i = index">
              <div style="display: flex; flex-direction: column; gap: 0.5rem" [class]="'classification' + i">
                <h5 style="font-weight: 500; margin: 0; padding: 0">
                  {{ classification.classificationTypeIntitule }}
                </h5>
                <div style="display: flex; gap: 1em">
                  <span class="dx-button-mode-text dx-state-focused chip">{{
                    classification.classificationValueCode
                    }}</span>
                  <span class="dx-button-mode-text dx-state-focused chip">{{
                    classification.classificationValueIntitule
                    }}</span>
                </div>
              </div>
            </div>
            <dx-form id="form" [formData]="article" labelLocation="top"
              [readOnly]="!isModificationEnCours && !isCreation">
              <dxi-item itemType="group">
                <dxi-item dataField="codeBo" editorType="dxTextBox" [label]="{ text: 'Code' }"
                  [editorOptions]="{ maxLength: 36 }"></dxi-item>
                <dxi-item dataField="libelleLong" editorType="dxTextBox" [label]="{ text: 'Intitulé' }"
                  [editorOptions]="{ maxLength: 100 }">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
              </dxi-item>
            </dx-form>
          </div>

          <app-tarif-article-simplifie [articleId]="article?.id"></app-tarif-article-simplifie>
        </div>
      </div>
      <dx-form #form id="form" [formData]="article" labelLocation="top"
        [readOnly]="!isModificationEnCours && !isCreation">
        <dxi-item itemType="tabbed">
          <dxo-tab-panel-options [showNavButtons]="true" [onSelectionChanged]="onTabSelectionChanged"></dxo-tab-panel-options>

          <!-- Onglet Photos -->
          <dxi-tab title="Photos">
            <div *ngIf="listPhotos.length === 0">
              Il n'y a pas de photos dans les documents.
            </div>
            <dx-gallery *ngIf="listPhotos.length > 0"
              #gallery
              id="gallery"
              [dataSource]="listPhotos"
              [height]="300"
              [loop]="true"
              [showNavButtons]="true"
              [showIndicator]="true"
            >
              <div *dxTemplate="let galleryItem of 'item'" class="gallery-item">
                <img onclick="window.open(this.src)" class="photos-gallery" [alt]="galleryItem.fileName" [src]="urlPhoto + galleryItem.id" />
                <div style="margin-top: 10px">{{ galleryItem.fileName }}</div>
              </div>
            </dx-gallery>
          </dxi-tab>

          <!-- Onglet Documents -->
          <dxi-tab title="Documents">
            <app-document-list [objectId]="article.id" [objectType]="objectType.article" (onDocumentSupprime)="onDocumentSupprime($event)"></app-document-list>
          </dxi-tab>

          <!-- Onglet Notes -->
          <dxi-tab title="Notes">
            <app-notes-list [objectType]="objectType.article" [objectId]="article.id"></app-notes-list>
          </dxi-tab>

          <!-- Onglet Bons d'achat -->
          <dxi-tab title="Bons d'achat" *ngIf="article.isBonAchat">
            <app-bon-achat-datagrid [articleId]="article.id"></app-bon-achat-datagrid>
          </dxi-tab>
        </dxi-item>
      </dx-form>
    </div>

    <!-- Relation n à n (datagrids) -->
    <div style="grid-column: span 2">
      <dx-form #form id="form" [formData]="article" labelLocation="top"
        [readOnly]="!isModificationEnCours && !isCreation">
        <dxi-item itemType="tabbed">
          <dxo-tab-panel-options [showNavButtons]="true"
            [onSelectionChanged]="onTabSelectionChanged"></dxo-tab-panel-options>
          <dxi-tab title="Général">
            <dxi-item itemType="group" [colCount]="4">
              <dxi-item dataField="type" [label]="{ text: 'Type de l\'article' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: articleTypeDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelle',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelle'],
                  showDataBeforeSearch: true,
                }">
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="rapportTarifaire" [label]="{ text: 'Rapport tarifaire' }" editorType="dxNumberBox"
                [editorOptions]="{ format: { type: 'fixedPoint', precision: 4 } }"
              ></dxi-item>
              <dxi-item dataField="rapportPhysique" [label]="{ text: 'Rapport physique' }" editorType="dxNumberBox"
                [editorOptions]="{ format: { type: 'fixedPoint', precision: 4 } }"
              ></dxi-item>
              <dxi-item dataField="dureeGarantieMois" [label]="{ text: 'Garantie'}" editorType="dxNumberBox"
                [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"
              ></dxi-item>
              <dxi-item *ngIf="!isCreation && (!isModificationEnCours || (isModificationEnCours && !canModifierPrixRevient))"
                dataField="prixRevient" editorType="dxNumberBox"
                [editorOptions]="{format: {type: 'fixedPoint', precision: 3}, readOnly:'true'}">
                <dxo-label template="prixRevientHistoryLabelTemplate"></dxo-label>
              </dxi-item>

              <dxi-item *ngIf="!isCreation && isModificationEnCours && canModifierPrixRevient"
                dataField="prixRevient" editorType="dxNumberBox"
                [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 }, readOnly: 'true' }"
              >
                <dxo-label template="prixRevientLabelTemplate"></dxo-label>
              </dxi-item>

              <dxi-item dataField="quantiteMinimumVente" editorType="dxNumberBox"
                [label]="{ text: 'Quantité minimum de vente' }" [editorOptions]="{
                  format: { type: 'fixedPoint', precision: 3 },
                }"></dxi-item>
              <dxi-item dataField="uniteBaseId" [label]="{ text: 'Unité de base' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: uniteDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule'],
                  showDataBeforeSearch: true,
                  value: uniteBaseSelectedId,
                }"
              ></dxi-item>
              <dxi-item dataField="nombreNumeroSerie" [label]="{ text: 'Nombre n° série' }" editorType="dxNumberBox"
                [editorOptions]="{
                  format: { type: 'fixedPoint', precision: 0 },
                }"></dxi-item>
              <dxi-item dataField="codeTarifRevatua" [label]="{ text: 'Code tarif REVATUA' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: tarifRevatuaDataSource,
                  searchEnabled: true,
                  displayExpr: codeTarifRevatuaDisplayExpr,
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule', 'code'],
                  showDataBeforeSearch: true,
                  placeholder: codeTarifRevatuaPlaceholder,
                }"></dxi-item>
              <dxi-item dataField="stockageRevatua" [label]="{ text: 'Stockage REVATUA' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: stockageRevatuaList,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule'],
                  showDataBeforeSearch: true,
                  placeholder: stockageRevatuaPlaceholder,
                }"
              ></dxi-item>
              <dxi-item
                dataField="articleAssuranceParDefautId"
                [label]="{text: 'Article assurance'}"
                editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: articleAssuranceDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelleLong',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelleLong', 'codeBo', 'libelleCourt'],
                  showDataBeforeSearch: true,
                }"
              ></dxi-item>
              <dxi-item dataField="avecVariation" [label]="{text:'Activer les variantes'}" editorType="dxSwitch"></dxi-item>
              <dxi-item itemType="empty" [colSpan]="1"></dxi-item>
              <dxi-item itemType="group" caption="Conditionnements">
                <dxi-item dataField="conditionnementTarifaireVenteId" [label]="{ text: 'Tarifaire' }"
                  editorType="dxSelectBox" [editorOptions]="{
                  dataSource: conditionnementDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelle',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelle'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
                <dxi-item *ngIf="!isCreation" dataField="conditionnementVenteId" [label]="{ text: 'Vente' }"
                  editorType="dxSelectBox" [editorOptions]="{
                  dataSource: conditionnementDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelle',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelle'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
                <dxi-item *ngIf="!isCreation" dataField="conditionnementAchatId" [label]="{ text: 'Achat' }"
                  editorType="dxSelectBox" [editorOptions]="{
                  dataSource: conditionnementDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelle',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelle'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
              </dxi-item>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="Variantes" *ngIf="articleAvecVariation">
            <app-variantes-article-list [articleId]="article?.id" [articleLibelleLong]="article?.libelleLong"></app-variantes-article-list>
          </dxi-tab>
          <dxi-tab title="Tarifs">
            <dxi-item itemType="group" caption="Tarifs" [disabled]="isCreation" colSpan="4">
              <app-tarif-article-list [articleId]="article?.id"></app-tarif-article-list>
            </dxi-item>

            <dxi-item itemType="group" caption="Opérations commerciales" *ngIf="!isCreation">
              <app-operations-commerciales-pour-article-detail [articleId]="article?.id"></app-operations-commerciales-pour-article-detail>
            </dxi-item>
          </dxi-tab>
          <!-- Onglet Comptabilité -->
          <dxi-tab title="Compta / Douane">
            <dxi-item itemType="group" caption="Comptabilité" [colCount]="2">
              <dxi-item dataField="taxeGroupeId" [label]="{ text: 'Groupe de taxes' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: taxeGroupeDataSource,
                  searchEnabled: true,
                  displayExpr: compteGeneralDisplayExpr,
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule'],
                  showDataBeforeSearch: true,
                }">
              </dxi-item>

              <dxi-item dataField="compteCharge" [label]="{ text: 'Compte de charge' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: comptesGeneraux,
                  searchEnabled: true,
                  displayExpr: compteGeneralDisplayExpr,
                  valueExpr: 'code',
                  showClearButton: true,
                  searchExpr: ['code', 'intitule'],
                  showDataBeforeSearch: true,
                  placeholder:
                    infosHeriteesDeLaClassification?.compteCharge ??
                    (isInterfaceComptableActive
                      ? 'Nécessaire pour l\'interface comptable'
                      : 'Sélection...'),
                }"></dxi-item>

              <dxi-item dataField="compteProduit" [label]="{ text: 'Compte de produit' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: comptesGeneraux,
                  searchEnabled: true,
                  displayExpr: compteGeneralDisplayExpr,
                  valueExpr: 'code',
                  showClearButton: true,
                  searchExpr: ['code', 'intitule'],
                  showDataBeforeSearch: true,
                  placeholder:
                    infosHeriteesDeLaClassification?.compteProduit ??
                    (isInterfaceComptableActive
                      ? 'Nécessaire pour l\'interface comptable'
                      : 'Sélection...'),
                }"></dxi-item>

              <dxi-item dataField="compteVariationStock" [label]="{ text: 'Compte variation de stock' }"
                editorType="dxSelectBox" [editorOptions]="{
                  dataSource: comptesGeneraux,
                  searchEnabled: true,
                  displayExpr: compteGeneralDisplayExpr,
                  valueExpr: 'code',
                  showClearButton: true,
                  searchExpr: ['code', 'intitule'],
                  showDataBeforeSearch: true,
                  placeholder:
                    infosHeriteesDeLaClassification?.compteVariationStock ??
                    (isInterfaceComptableActive
                      ? 'Nécessaire pour l\'interface comptable'
                      : 'Sélection...'),
                }"></dxi-item>

              <dxi-item dataField="compteStock" [label]="{ text: 'Compte de stock' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: comptesGeneraux,
                  searchEnabled: true,
                  displayExpr: compteGeneralDisplayExpr,
                  valueExpr: 'code',
                  showClearButton: true,
                  searchExpr: ['code', 'intitule'],
                  showDataBeforeSearch: true,
                  placeholder:
                    infosHeriteesDeLaClassification?.compteStock ??
                    (isInterfaceComptableActive
                      ? 'Nécessaire pour l\'interface comptable'
                      : 'Sélection...'),
                }"></dxi-item>

              <dxi-item dataField="compteRemise" [label]="{ text: 'Compte Remise' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: comptesGeneraux,
                  searchEnabled: true,
                  displayExpr: compteGeneralDisplayExpr,
                  valueExpr: 'code',
                  showClearButton: true,
                  searchExpr: ['code', 'intitule'],
                  showDataBeforeSearch: true,
                  placeholder:
                      infosHeriteesDeLaClassification?.compteRemise ??
                      (isInterfaceComptableActive
                        ? 'Nécessaire pour l\'interface comptable'
                        : 'Sélection...'),
                }"></dxi-item>
            </dxi-item>
            <dxi-item itemType="group" caption="Douane" [colCount]="2">
              <dxi-item dataField="nomenclatureDouaneId" [label]="{ text: 'Nomenclature douanière' }"
                editorType="dxSelectBox" [editorOptions]="{
                  dataSource: nomenclatureDouaneDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule', 'codeSH'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
              <dxi-item dataField="suffixeValeurDouaneId" [label]="{ text: 'Suffixe valeur douane' }"
                editorType="dxSelectBox" [editorOptions]="{
                  dataSource: suffixeValeurDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule', 'code'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
              <dxi-item dataField="codeExonerationDouaneId" [label]="{ text: 'Code d\'exonération douanière' }"
                editorType="dxSelectBox" [editorOptions]="{
                  dataSource: codeExonerationDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule', 'code'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
              <dxi-item dataField="paysOrigineId" [label]="{ text: 'Pays d\'origine' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: paysDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule'],
                  showDataBeforeSearch: true,
                }"></dxi-item>
              <dxi-item dataField="tauxSucre" [label]="{ text: 'Taux de sucre' }" editorType="dxNumberBox"
                [editorOptions]="{
                  format: { type: 'fixedPoint', precision: 3 },
                }"></dxi-item>
              <dxi-item dataField="litrage" [label]="{ text: 'Litrage' }" editorType="dxNumberBox" [editorOptions]="{
                  format: { type: 'fixedPoint', precision: 3 },
                }"></dxi-item>
            </dxi-item>
          </dxi-tab>
          <!-- Onglet Stock -->
          <dxi-tab title="Stock" *ngIf="article.type === ArticleType.backOffice" [disabled]="isCreation">
            <app-lot-stockage-datagrid *ngIf="!isCreation" [articleId]="article?.id" [currentSiteId]="currentSiteId"
              [dataGridMarginTop]="'calc(100vh - 300px)'" [isInFicheArticle]="true"
              [configFiltre]="configFiltreLot"></app-lot-stockage-datagrid>
          </dxi-tab>
          <!-- Onglet Achats -->
          <dxi-tab title="Achats">
            <dxi-item dataField="fournisseurPrincipalId" [label]="{ text: 'Fournisseur principal' }"
              editorType="dxSelectBox" [editorOptions]="{
                dataSource: partenaireDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule'],
                showDataBeforeSearch: true,
              }"></dxi-item>

            <dxi-item itemType="group" caption="Références fournisseurs" *ngIf="!isCreation">
              <dx-data-grid id="gridContainer" [dataSource]="referencesFournisseurs" [allowColumnReordering]="true"
                [columnAutoWidth]="true" [allowColumnResizing]="true" [showBorders]="true">
                <!-- Toolbar -->
                <dxo-toolbar>
                  <dxi-item>
                    <div *dxTemplate>
                      <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setReferencesFournisseurs()"></dx-button>
                    </div>
                  </dxi-item>
                </dxo-toolbar>
                <dxi-column dataField="codeArticleFournisseur" width="200" caption="Référence"></dxi-column>
                <dxi-column dataField="fournisseurIntitule" caption="Libellé"></dxi-column>
              </dx-data-grid>
            </dxi-item>
          </dxi-tab>
          <!-- Onglet Logistique -->
          <dxi-tab title="Logistique" [colCount]="2">
            <dxi-item itemType="group" caption="Conditionnements" [colSpan]="2">
              <app-conditionnement-list *ngIf="!isCreation" [articleId]="article?.id"
                [uniteBaseId]="article.uniteBaseId"></app-conditionnement-list>
            </dxi-item>
            <dxi-item itemType="group" caption="Codes barres" *ngIf="!isCreation" [colSpan]="2">
              <app-code-barre-article-detail [articleId]="article.id"
                [uniteBaseId]="article.uniteBaseId"></app-code-barre-article-detail>
            </dxi-item>
          </dxi-tab>
          <!-- Onglet Numéro de série -->
          <dxi-tab title="Numéro de série" *ngIf="article.nombreNumeroSerie > 0">
            <app-registre-numero-serie-list [articleId]="article.id" [canSelectionnerArticle]="false"
              [height]="'68vh'"></app-registre-numero-serie-list>
          </dxi-tab>
          <!-- Onglet Bons -->
          <dxi-tab title="Historiques">
            <dxi-item itemType="tabbed">
              <dxi-tab *ngIf="article.type === ArticleType.backOffice" title="Bons">
                <app-article-detail-bon-list [articleId]="article.id" [currentPartenaireId]="currentPartenaireId"
                  [height]="'75vh'"></app-article-detail-bon-list>
              </dxi-tab>
              <dxi-tab *ngIf="article.type === ArticleType.backOffice" title="Mouvements">
                <app-mouvement-stock-datagrid *ngIf="!isCreation" [configFiltre]="configFiltre"
                  [articleId]="article?.id" [currentPartenaireId]="currentPartenaireId"
                  [dataGridHeight]="700"></app-mouvement-stock-datagrid>
              </dxi-tab>
            </dxi-item>
          </dxi-tab>
          <!-- Onglet Caractéristique -->
          <dxi-tab title="Caractéristiques">
            <dxi-item height="300">
            <app-gestion-caracteristiques  [objectId]="article.id"
              [objectType]="objectType.article"></app-gestion-caracteristiques>
            </dxi-item>
          </dxi-tab>

          <!-- Onglet Classifications -->
          <dxi-tab title="Classifications" [colCount]="2">
            <dxi-item itemType="group" [colCount]="12">
              <dxi-item *ngIf="article?.articleParentId != null">
                <div *dxTemplate style="display: flex; justify-content: center; align-items: center;">
                  <dx-button icon="parentfolder" hint="Voir l'article parent" (onClick)="navigateToArticleParent()" [width]="24" [height]="24"></dx-button>
                </div>
              </dxi-item>
              <dxi-item dataField="articleParentId"
                [label]="{ text: 'Article parent' }"
                editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: articleDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelleLong',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelleLong', 'codeBo'],
                  showDataBeforeSearch: true
                }"
                [colSpan]="11"
              ></dxi-item>
            </dxi-item>
            <dxi-item dataField="familleId" [label]="{ text: 'Famille d\'articles' }" editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: familleDataSource,
                searchEnabled: true,
                displayExpr: 'libelleLong',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelleLong'],
                showDataBeforeSearch: true,
              }"
            ></dxi-item>

            <!-- DataGrid Classifications -->
            <dxi-item *ngIf="!isCreation" [colSpan]="2">
              <div *dxTemplate>
                <app-classification-datagrid [classificationsDto]="classifications" [objectType]="objectType.article"
                  [itemId]="article?.id"
                ></app-classification-datagrid>
              </div>
            </dxi-item>
          </dxi-tab>

          <!-- Onglet Audit -->
          <dxi-tab title="Audit">
            <app-audit-piste objectType="article" [objectId]="article.id"></app-audit-piste>
          </dxi-tab>
        </dxi-item>

        <div *dxTemplate="let data of 'prixRevientLabelTemplate'">
          <div id="template-content">
            <span>Prix de revient</span>
            <a (click)="opentPrixRevientPopup()" href="javascript:void(0);"
              style="text-decoration: none; margin-left: 5px">
              <i class="dx-icon-edit"></i>
            </a>
          </div>
        </div>
        <div *dxTemplate="let data of 'prixRevientHistoryLabelTemplate'">
          <div id="template-content">
            <span>Prix de revient</span>
            <a (click)='loadHistorique()' href="javascript:void(0);" style="text-decoration: none; margin-left: 5px;">
              <i class="dx-icon-clock"></i>
            </a>
          </div>
        </div>
      </dx-form>
    </div>
  </div>

  <div *ngIf="!chargementInitialFait" style="display: flex; justify-content: center; align-items: center;">
    <dx-load-indicator [visible]="!chargementInitialFait" height="150" width="150"></dx-load-indicator>
  </div>
</div>

<!-- Popup : modification du prix de revient -->
<dx-popup [width]="320" [height]="150" title="Modifier le prix de revient" [(visible)]="showEditPrixRevientPopup"
  [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <div style="text-align: center">
      <dx-number-box [format]="{ type: 'fixedPoint', precision: 3 }" [(value)]="article.prixRevient"
        [width]="150"></dx-number-box>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      " *ngIf="!isCreation && isModificationEnCours">
      <dx-button text="Enregistrer" type="success" icon="save" (onClick)="onUpdatePrixRevient()"
        style="margin-right: 10px"></dx-button>
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo" (onClick)="
          this.showEditPrixRevientPopup = false;
          this.article.prixRevient = this.prixRevientAvantModification
        ">
      </dx-button>
    </div>
  </div>
</dx-popup>

<!-- Popup : historique PMP -->
<dx-popup [showTitle]="true" height="auto" minHeight="525" title="Historique des prix de revient"
  [(visible)]="showPopupHistorique">
  <div style="display: flex; flex-direction:column; justify-content: space-between"
    *dxTemplate="let data = model; of: 'content'">
    <dx-data-grid [dataSource]="historiquePmp">
      <dxi-column dataField="dateChangement" caption="Date de changement" [allowEditing]="false" dataType="date"
        format="dd/MM/yyyy HH:mm"></dxi-column>
      <dxi-column dataField="pmpOriginal" caption="Ancien PMP" [allowEditing]="false">
        <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
      </dxi-column>
      <dxi-column dataField="nouveauPmp" caption="Nouveau PMP" [allowEditing]="false">
        <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
      </dxi-column>
      <dxi-column dataField="description" caption="Description" [allowEditing]="false"></dxi-column>
      <dxi-column dataField="createdByUser" caption="Changé par"></dxi-column>
    </dx-data-grid>
    <div style="display: flex; justify-content: end; align-items: center; margin-top: 20px">
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo"
        (onClick)="this.showPopupHistorique=false;" />
    </div>
  </div>
</dx-popup>

<!-- Popup : Confirmation création d'article groupe taxe -->
<dx-popup [width]="450" [height]="'auto'" [(visible)]="showConfirmationTaxePopup" [showTitle]="true"
  [dragEnabled]="false" [showCloseButton]="true" title="Confirmation d'ajout">
  <div *dxTemplate="let data of 'content'">
    Cet article n'a pas de groupe de taxe.<br />
    Voulez-vous vraiment le créer ?
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      ">
      <dx-button text="Enregistrer" type="success" icon="save" (onClick)="createArticle()"
        style="margin-right: 10px"></dx-button>
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo"
        (onClick)="this.showConfirmationTaxePopup = false">
      </dx-button>
    </div>
  </div>
</dx-popup>